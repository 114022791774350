import React from "react";
import PropTypes from "prop-types";
import Layout from "../components/Layout";
import Pagination from "../components/Pagination";
import CategoryBlocks from "../components/CategoryBlocks";
import DisplayPosts from "../components/DisplayPosts";
import Sidebar from "../components/Sidebar";
import Container from "../components/Container";
import SEO from "../components/SeoHelper";
import BreadCrumb from "../components/BreadCrumb";
import routes from "../routes";
import { categoryToSlug } from "../common/toSlug";
import { useTheme } from "../components/ThemeContext";

export default function Category({ pageContext }) {
  const { name, index, pathPrefix, pageCount, group = [] } = pageContext;
  const { theme } = useTheme();

  return (
    <Layout>
      <SEO
        title={`${name}${index !== 1 ? ` | Page ${index}` : ""}`}
        description={`Post in the category of ${name} by ${theme.title}`}
      />
      <Container>
        <div>
          <BreadCrumb
            arrayOfLinkNameValues={[
              { name: "Blog", link: routes.blog },
              { name: name, link: name === "Most popular" ? routes.popular : categoryToSlug(name) },
            ]}
          />
          <h1 className="mb-4 text-xl font-bold md:text-3xl">{name}</h1>
        </div>
        <div className="flex flex-wrap">
          <div className="w-full md:w-3/4 md:pr-16 lg:pr-24">
            <DisplayPosts posts={group} columns="3" />
          </div>
          <div className="w-full md:w-1/4">
            <Sidebar />
          </div>
        </div>
        <div className="text-center">
          <Pagination pageCount={pageCount} index={index} pathPrefix={pathPrefix} />
        </div>
        <footer>
          <h2 className="mb-2 text-xl font-bold md:text-2xl md:mb-4">Visit other categories</h2>
          <CategoryBlocks />
        </footer>
      </Container>
    </Layout>
  );
}
Category.propTypes = { pageContext: PropTypes.any.isRequired };
